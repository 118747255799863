import { Fragment } from 'react'
import Cleave from 'cleave.js/react'
import styled from '@emotion/styled'
import { useState } from 'react'
import { Flex, Box } from '@rebass/emotion'
import { Field as FinalField } from 'react-final-form'

import Error from './error'

const Div = styled.div`
  width: 100%;
`

const Field = ({
  name,
  type,
  validate,
  validateFields,
  placeholder,
  cleave,
  children,
  label,
  ...rest
}) => {
  /**
   * TODO: Remove this hack later when the meta.active issue is fixed in final form
   */
  const [active, setActive] = useState(false)
  const onFocus = () => setActive(true)
  const onBlur = () => setActive(false)

  return (
    <FinalField name={name} type={type} validate={validate} validateFields={validateFields}>
      {({ input, meta: { error, touched }, meta }) => (
        <Div onFocus={onFocus} onBlur={onBlur}>
          {type == 'select' ? (
            <select {...input} children={children} id={name} {...rest} />
          ) : cleave ? (
            <Cleave
              {...cleave}
              {...input}
              placeholder={placeholder}
              id={name}
              {...rest}
            />
          ) : (
            type == 'checkbox' ? (
              <Flex flexDirection="row">
                <Box width={15} mr={2}><input {...input} placeholder={placeholder} id={name} {...rest} /></Box>
                <Box width={1}>{label}</Box>
              </Flex>
            ) : (
              <input {...input} placeholder={placeholder} id={name} {...rest} />
            )
          )}
          {!active && touched && error && <Error>{error}</Error>}
        </Div>
      )}
    </FinalField>
  )
}

export default Field
