import validator from 'validator'
import { memoize } from 'lodash'
import nexus from 'services/nexus'
import { tlds } from 'services/utils'

/**
 * Validation Helpers
 */
export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

export const isRequired = message => value => {
  if (!value) return message
}

export const isAlpha = message => value => {
  if (!validator.isAlpha(value)) return message
}

export const isFirstName = value => {
  const splitNames = value.split(' ')

  splitNames.forEach(element => {
    if( !validator.isAlpha(element) ) {
      return 'First name must contain letters only.'
    }
  });
}

export const isEmail = value => {
  if (!validator.isEmail(value))
    return 'Please enter a valid email address so we can contact you.'

  const splitEmail = value.split('.')
  const currentTld = splitEmail[splitEmail.length - 1].toLowerCase()

  if (!tlds.some(tld => tld === currentTld))
    return 'Please enter a valid email address.'
}

export const isCreditCard = value => {
  if (!validator.isCreditCard(value)) return 'Credit Card Number is invalid.'
}

export const isCvv = value => {
  if (value.length !== 3 && value.length !== 4) return 'CVC is invalid.'
}

/**
 * Form Field Validation
 */
export const firstNameIsRequired = isRequired('Your first name, please.')
export const firstNameIsAlpha = isAlpha('First name must contain letters only.')
export const lastNameIsRequired = isRequired('Last name is required.')
export const lastNameIsAlpha = isAlpha('Last name must contain letters only.')
export const emailIsRequired = isRequired(
  'Please enter a valid email address.'
)
export const passwordIsRequired = isRequired(
  'Please enter a password.'
)
export const passwordConfirmationIsRequired = isRequired(
  'Please confirm your password.'
)
export const passwordConfirmationMatch = confirmation => value => {
  if (confirmation !== value) return 'Passwords must match'
}
export const phoneIsRequired = isRequired('Phone is required')
export const dateTimeIsRequired = isRequired(
  'Please select a time that works best.'
)
export const cardNumberIsRequired = isRequired(
  `We'll need a valid card number to get started.`
)
export const cvvIsRequired = isRequired('Please check again.')
export const expirationMonthIsRequired = isRequired(
  'Expiration Month is required.'
)
export const expirationMonthIsValidWithExpirationYear = (
  value,
  { expirationYear }
) => {
  if (
    value &&
    expirationYear &&
    expirationYear == new Date().getFullYear() - 2000 &&
    parseInt(value) <= parseInt(('0' + (new Date().getMonth() + 1)).slice(-2))
  )
    return 'Expiration Month is expired.'
}
export const expirationYearIsRequired = isRequired(
  'Expiration Year is required.'
)
export const expirationYearIsValidWithExpirationMonth = (
  value,
  { expirationMonth }
) => {
  // if (
  //   value &&
  //   expirationMonth &&
  //   parseInt(expirationMonth) <=
  //     parseInt(('0' + (new Date().getMonth() + 1)).slice(-2)) &&
  //   value == new Date().getFullYear() - 2000
  // )
  //   return 'Oops! This date has expired.'
}
export const addressIsRequired = isRequired('Your address is required.')
export const cityIsRequired = isRequired('Your city is required.')
export const stateIsRequired = isRequired('Please select a state.')
export const countryIsRequired = isRequired('Please select a country.')
export const zipcodeIsRequired = isRequired(`We're going to need this too.`)
export const agreeToTerms = isRequired('You must agree to our terms.')

export const emailAvailable = memoize(async email => {
  const { exists } = (await nexus.get(`/verifications/${email}`)).body

  if (exists)
    return 'This email has already been registered. Login using this email or create a new account with a different email.'
})
