import styled from '@emotion/styled'
import { Text } from '@rebass/emotion'

const Label = styled(Text)()

Label.defaultProps = {
  m: [10],
  color: 'asphalt',
  bg: 'tranparent',
  fontFamily: 'roboto',
  textAlign: 'left'
}

export default Label
