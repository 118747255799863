import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Field } from 'react-final-form'
import { Box, Text } from '@rebass/emotion'

import FlexPointer from 'components/flex-pointer'

export const CheckboxIcon = styled(Box)`
  position: relative;
  border: 1px solid #979797;
  background: white;
  ${props =>
    props.checked &&
    `
    :after {
      position: absolute;
      top: 4px;
      left: 3px;
      width: 10px;
      height: 10px;
      display: block;
      color: #C21F2C;
      content: "✔";
      font-size: 12px;
    }
  `};
`

CheckboxIcon.propTypes = {
  checked: PropTypes.bool
}

CheckboxIcon.defaultProps = {
  checked: false,
  size: [20],
  bg: '#f1f1f1'
}

const Checkbox = ({ name, children }) => (
  <Field name={name}>
    {({ input: { value, onChange } }) => (
      <FlexPointer
        onClick={() => onChange(!value)}
        flexWrap="nowrap"
        alignItems="top"
      >
        <CheckboxIcon checked={!!value} />
        <Text
          width={[1]}
          fontFamily="roboto"
          fontSize={[15]}
          lineHeight={[1.3]}
          color="gorilla"
          ml={[10]}
        >
          {children}
        </Text>
      </FlexPointer>
    )}
  </Field>
)

export default Checkbox
