import styled from '@emotion/styled'
import { Flex } from '@rebass/emotion'

const Group = styled(Flex)()

Group.defaultProps = {
  flexWrap: ['wrap', 'nowrap'],
  mb: [15]
}

export default Group
