import styled from '@emotion/styled'
import { Box } from '@rebass/emotion'

const Error = styled(Box)`
`

Error.defaultProps = {
  mt: [10],
  color: 'lobster',
  bg: 'tranparent',
  fontFamily: 'roboto',
  fontWeight: '400',
  fontSize: '11pt',
  textAlign: 'left'
}

export default Error
