import styled from '@emotion/styled'

export default styled('form')`
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'],
  input[type='search'],
  input[type='tel'],
  input[type='color'],
  input[type='file'],
  textarea,
  select {
    box-sizing: border-box;
    width: 100%;
    background: white;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    font-family: ${props => props.theme.fonts.roboto};
    font-size: 16px;
    color: #484848;
    height: 56px;
    padding: 10px;
    :placeholder {
      color: #838383;
    }
  }
`
