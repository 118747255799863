import styled from '@emotion/styled'
import withReveal from 'react-reveal/withReveal'
import Fade from 'react-reveal/Fade'
import { useRef } from 'react'
import { Box, Flex, Heading } from '@rebass/emotion'

import { CloseButton } from 'components/buttons'

const ModalBackdrop = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.closeable ? 'pointer' : 'default')};
  text-align: center;
  z-index: 10000;
`

const ModalBody = withReveal(
  styled(Box)`
    padding: 10px;
    background: #fff;
    min-width: 300px;
    border-radius: 10px;

    h2 {
      font-family: ${props => props.theme.fonts.oswald};
      font-weight: 400;
      color: ${props => props.theme.colors.gorilla};
      margin-bottom: 30px;
    }
    h3 {
      font-family: ${props => props.theme.fonts.oswald};
      font-weight: 400;
      color: ${props => props.theme.colors.gorilla};
      margin-bottom: 15px;
    }
    p {
      font-family: ${props => props.theme.fonts.roboto};
      color: #4c4c4c;
      line-height: 1.5;
      margin-bottom: 15px;
    }
  `,
  <Fade />
)

const Modal = ({ isOpen, onClose, title, closeable, children }) => {
  const node = useRef()

  return isOpen ? (
    <ModalBackdrop
      closeable={closeable}
      onClick={e => {
        if (node.current.contains(e.target)) return
        onClose()
      }}
    >
      <ModalBody width={['90%', 800]} mx="auto">
        <CloseButton onClick={onClose}>+</CloseButton>
        <Box ref={node} p={[4]}>
          {title && <Heading as="h2">{title}</Heading>}
          {children}
        </Box>
      </ModalBody>
    </ModalBackdrop>
  ) : null
}

Modal.defaultProps = {
  closeable: true
}

export default Modal
